<template>
  <div class="row green_bg mx-0 h-100 py-5">
    <div class="bg_overlay"></div>
    <div class="container-lg" >
      <div class="row">
        <div class="col-12 above">
          <div class="row mx-0 justify-content-center">
            <div class="col-md-6 heading_title">
              <div class="row jusstify-content-center">
                <div class="col-12 text-center">
                  View our latest news
                </div>
              </div>
              <div class="row mt-4 mb-5 justify-content-center">
                <div class="col-6 heading_title_underline">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5" v-for="article in news.slice(0, 1)" :key="article.id" >
              <LatestNews :item="article" />
            </div>
            <div class="col-lg-7">
              <div class="row">
                <div class="col-12 col-md-6 col-lg-4" v-for="article in news.slice(1, 7)" :key="article.id">
                  <LatestNewsSmall :item="article" />
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mx-0 mt-4">
            <div class="col-auto">
              <Button color="green" btnText="View All News" icon="arrow" @buttonClicked="goTo('news')" >
                <IconArrowForward color="gold" size="size20" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    LatestNews: defineAsyncComponent(() => import('./components/LatestNews.vue')),
    LatestNewsSmall: defineAsyncComponent(() => import('./components/LatestNewsSmall.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue'))
  },
  name: 'HomeLatestNews',
  computed: {
    ...mapGetters([
      'news'
    ])
  },
  methods: {
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    }
  }
}
</script>

<style scoped>
  .heading_title {
    color: #FFFFFF;
    font-family: "quicksand", Sans-serif;
    font-size: 3.5rem;
    font-weight: 100;
    line-height: 1.3em;
  }
  .heading_title_underline {
    border-bottom: 2px solid var(--gold-color);
  }
  .green_bg {
    background-color: transparent;
    background-image: linear-gradient(rgb(13, 53, 28) 0%, rgb(26, 104, 54) 100%);
    position: relative;
    /* box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 13%); */
  }
  .bg_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    background-image: url(../../../../assets/images/bg.png);
    background-size: 22% auto;
    opacity: 0.07;
    border-radius: 22px;
    box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
  }
  .above {
    z-index: 2;
    position: relative;
  }
</style>
